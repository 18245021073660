.leaflet-container.crosshair-cursor-enabled * {
    cursor:crosshair;
}

.leaflet-control-attribution {
    display: none;
}

.legend-table {
    background-color: white;
}

.legend-table td {
    vertical-align: middle;
    border: 0;
}

.legend-table tr, .legend-table th {
    border: 0;
}

.legend-table td div {
    border-radius: 50%;
    margin: auto;
}

.lat-lng-table table, .lat-lng-table td {
    border: none;
}
